<script lang="ts" setup>
const { t } = useT();
const { data: appInitData } = useAppInitData();

const level = computed(() => appInitData.value?.rankLeague?.currentLevel);
const currentSection = computed(() => appInitData.value?.rankLeague?.currentSection);

const { colorSchema } = useStatusColor({ statusId: currentSection.value?.id });
</script>

<template>
	<NuxtLink to="/vip/" class="vip">
		<div class="inner-content">
			<NuxtImg
				:src="`/nuxt-img/vip/status/icon-${currentSection?.id || 1}.png`"
				alt="current status"
				width="auto"
				height="46"
				format="avif"
				loading="lazy"
			/>
			<AText class="text-neutral-100" :modifiers="['medium']">
				<template v-if="!appInitData?.isGuest && level"> {{ t("VIP Level") }} - {{ level?.title }}</template>
				<template v-else>
					<i18n-t keypath="VIP Club {key}" tag="div">
						<template #key>
							<span class="text-tertiary-80">{{ t("Up to 20%") }}</span>
						</template>
					</i18n-t>
				</template>
			</AText>
		</div>
	</NuxtLink>
</template>

<style lang="scss" scoped>
.vip.aside-menu-banner {
	background: linear-gradient(90deg, v-bind("colorSchema?.widgetColor") 0%, rgba(76, 94, 120, 0) 100%);
	.inner-content {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 8px;
	}
}
</style>
